import React from "react"
import styled from "styled-components"
import { rem, transparentize, fluidRange } from "polished"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { usePagination } from "react-use-pagination"

import SEO from "../containers/seo"
import HiddenHeading from "../components/hidden-heading"
import _Layout, { Main } from "../containers/layout"
import _CaseStudy from "../components/cards/case-study"
import _Filters from "../components/links/case-studies-filters"
import _Button from "../components/buttons/button"
import { createFilters } from "../utils/misc"

export default function CaseStudies({ data, pageContext: { type } }) {
  const { endIndex, nextEnabled, setNextPage } = usePagination({
    totalItems: data.caseStudies.edges.length,
    initialPageSize: 4,
  })

  return (
    <Layout>
      <SEO title="Case Studies" />
      <Filters type={type} filters={createFilters(type, data)} />
      <HiddenHeading>Case Studies</HiddenHeading>
      {data.caseStudies.edges
        .slice(0, endIndex + 1)
        .map(
          (
            {
              node: {
                title,
                client,
                thumbnail,
                featured,
                description: { description },
                slug,
                category,
                tags,
              },
            },
            index,
          ) => (
            <CaseStudy
              key={slug}
              image={<Img fluid={thumbnail.localFile.childImageSharp.fluid} />}
              clientLogo={
                <Img
                  fluid={client.logo.localFile.childImageSharp.fluid}
                  imgStyle={{ width: "auto" }}
                />
              }
              featured={index === 0 ? true : featured}
              title={title}
              description={description}
              url={`/case-study/${slug}#content`}
              category={{
                text: category.text,
                url: `/case-studies/category/${category.url}`,
              }}
              tags={tags.map(({ id, text, url }) => ({
                id,
                text,
                url: `/case-studies/tag/${url}`,
              }))}
            />
          ),
        )}
      {nextEnabled && <LoadMoreButton onClick={setNextPage} />}
    </Layout>
  )
}

const CaseStudy = styled(_CaseStudy)`
  grid-column: main;
  justify-self: center;
`
const Filters = styled(_Filters)`
  grid-column: full;
  justify-self: center;
  width: 100%;
  padding: ${rem("10px")} 0;
  border: 1px solid ${props => transparentize(0.8, props.theme.color.dark)};
  border-left-width: 0;
  border-right-width: 0;
`
const LoadMoreButton = styled(_Button).attrs({ children: "load more" })`
  max-width: ${rem("179px")};
  justify-self: center;
  grid-column: main;
`
const Layout = styled(_Layout)`
  ${Main} {
    padding-top: ${rem("30px")};

    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "65px",
        toSize: "115px",
      },
      "400px",
      "1200px",
    )}
  }
`

export const pageQuery = graphql`
  query($filter: ContentfulCaseStudyFilterInput) {
    caseStudies: allContentfulCaseStudy(
      sort: { fields: [createdAt, featured], order: [DESC, DESC] }
      filter: $filter
    ) {
      edges {
        node {
          title
          category {
            text
            url
          }
          tags {
            id
            text
            url
          }
          description {
            description
          }
          slug
          client {
            logo {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 30) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          featured
          thumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 461, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    categories: allContentfulCaseStudy {
      distinct(field: category___text)
    }
    categorySlugs: allContentfulCaseStudy {
      distinct(field: category___url)
    }
    tags: allContentfulCaseStudy {
      distinct(field: tags___text)
    }
    tagSlugs: allContentfulCaseStudy {
      distinct(field: tags___url)
    }
  }
`
